<template>
  <UiTabList>
    <UiTab :to="{ name: 'register' }">
      Sign up
    </UiTab>
    <UiTab :to="{ name: 'login' }">
      Log in
    </UiTab>
  </UiTabList>
</template>
